<template>
    <div>
        <span class="social-title font-weight-bold pl-3 pr-3 pt-1 pb-1 pl-md-6 pr-md-6 pt-md-2 pb-md-2 white--text">{{title}}</span>
    </div>
</template>
<script>
    export default {
        name: 'sub-title',
        props: {
            title: ''
        }
    }
</script>
<style lang="scss" scoped>
    .social-title {
        background-color: $primary-color;
        font-size: 34px;
        border-top-left-radius: 20px;
    }

    @media (max-width: $screen-md) {
        .social-title {
            font-size: 16px;
            border-top-left-radius: 15px;
        }
    }

    @media (max-width: $screen-sm) {
        .social-title {
            font-size: 14px;
        }
    }
</style>