<template>
    <div class="page">
        <app-header :navIndex="2"></app-header>
        <section class="d-flex flex-column flex-md-row">
            <div class="col-md-6 section-container inner-lf order-md-0 pb-7 pb-md-0">
                <sub-title class="subTitle" :title="details.title"></sub-title>
                <section-title class="mb-2 hidden-md-and-up" :title="details.title"
                               :enTitle="details.enTitle"></section-title>
                <div class="desc mt-md-10 col-md-11" v-html="details.desc"></div>
                <div class="enTitle">{{details.enTitle}}</div>
            </div>
            <div class="col-md-5 pa-0 inner-box order-first order-md-1">
                <v-img :src="details.bg"></v-img>
                <div class="d-flex flex-row inner">
                    <v-img class="img order-md-0" :src="details.img"></v-img>
                    <div class="d-flex flex-column align-center align-md-end ml-md-5 logo-box order-first order-md-1">
                        <v-img class="logo pa-0" contain :src="details.logo"></v-img>
                        <div class="text-box">
                            <div class="value text-center" v-html="details.desc1"></div>
                            <div v-for="(item,index) in details.items" v-if="false">
                                <div class="text white--text">{{item.title}}</div>
                                <div class="value">{{item.value}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import SubTitle from "../../components/base/subtitle";
    import SectionTitle from "../company/components/section-title";
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            SectionTitle,
            SubTitle
        },
        data(){
            return {
                details: {
                    logo: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/beer/logo.png'),
                    title: this.$i18n.t('others.beer.title'),
                    enTitle: 'THE PREMIUM MALT’S',
                    desc: this.$i18n.t('others.beer.details'),
                    bg: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/beer/bg.jpg'),
                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/product/beer/img.png'),
                    desc1: 'OVERWHELMINGLY<br>DELICIOUS',
                    items: [{
                        title: '酒精度',
                        value: '5.5%'
                    }, {
                        title: '净含量',
                        value: '334ml'
                    }, {
                        title: '原麦汁浓度',
                        value: '12.5°P'
                    }]
                }
            }
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "啤酒"
            document.getElementById("bodyId").appendChild(div);
        },
    }
</script>
<style lang="scss" scoped>
    .inner-lf {
        margin-top: 160px;
        position: relative;
        .enTitle {
            position: absolute;
            bottom: 20px;
            left: -50px;
            width: 100%;
            text-align: right;
            font-size: 100px;
            line-height: 90px;
            color: rgba(183, 142, 85, 0.10);
        }
    }

    .inner-box {
        position: relative;
        .inner {
            position: absolute;
            bottom: 0;
            left: -90px;
            .img {
                width: 284px !important;
                height: 611px !important;
            }
            .logo-box {
                margin-top: -200px;
                .logo {
                    width: 221px !important;
                }
                .text-box {
                    margin-bottom: 80px;
                    .text {
                        font-size: 14px;
                    }
                    .value {
                        font-size: 25px;
                        /*font-size: 50px;*/
                        color: $primary-color;
                        margin-bottom: 100px;
                        height: 100px;
                        font-family: DINAlternate-Bold, DINAlternate;
                    }
                }

            }

        }
    }

    @media (max-width: $screen-md) {
        .inner-lf {
            margin-top: 62px;

            .subTitle, .enTitle {
                display: none;
            }
        }
        .inner-box {
            .inner {
                bottom: -20px;
                left: 0;
                .img {
                    width: 176px !important;
                    height: 376px !important;
                }
                .logo-box {
                    margin-top: 50px;
                    .logo {
                        width: 103px !important;
                    }
                    .text-box {
                        margin-bottom: 80px;
                        .text {
                            font-size: 10px;
                        }
                        .value {
                            font-size: 20px;
                            margin-bottom: 30px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }

</style>
